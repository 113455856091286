module controllers {
    export module master {

        interface IVariableDocumentListCtrlScope extends ng.IScope {
        }

        interface IVariableDocumentListParams extends ng.ui.IStateParamsService {
        }

        export class variableDocumentListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "$timeout",
                '$uibModal',
                'variableDocsTemplateService',
                '$state',
                '$stateParams',
                '$transitions'
            ];

            IsLoading: boolean = false;

            gridApi: uiGrid.IGridApi;
            myHook: any;

            apiList: uiGrid.IGridApi;

            constructor(private $scope: IVariableDocumentListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private variableDocsTemplateService: interfaces.master.IVariableDocsTemplateService,
                private $state: ng.ui.IStateService,
                private $stateParams: IVariableDocumentListParams,
                private $transitions: ng.ui.core.ITransition
            ) {
                this.loadVariableDocuments();

                this.myHook = $transitions.onSuccess({
                    to: 'auth.MasterData.VariableDocument',
                    from: 'auth.MasterData.VariableDocument.**'
                }, () => {
                        this.loadVariableDocuments();
                });

                $scope.$on('$destroy', () => {
                    $transitions.onStart({}, this.myHook)
                });
            }

            loadVariableDocuments() {

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'variableDocument'
                },
                    () => {
                        return this.variableDocsTemplateService.getList().query((data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }

            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;
            }


            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => {
                    this.registerGridApi(gridApi)
                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                             <div class="GridButton">
                                <button type="button" ng-click="grid.appScope.variableDocumentListCtrl.editVariableDocument(row.entity.Id)" class="btn btn-default btn-sm">
                                    <span class="fa fa-pencil"></span>
                                </button>
                            </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 150,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "Description",
                    displayName: "Description",
                    field: "Description",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }, {
                    name: "DocumentTitle",
                    displayName: "Document Title",
                    field: "DocumentTitle",
                    width: 320,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                },
                {
                    name: "IsActive",
                    displayName: "Active",
                    field: "IsActive",
                    width: 120,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`,
                }

                ]
            };

            public editVariableDocument(id: number) {
                this.$state.go("auth.MasterData.VariableDocument.Update", { variableDocumentId: id });
            }

            AddNew() {
                this.$state.go("auth.MasterData.VariableDocument.Update", { variableDocumentId: 0 });
            }
        }

        angular.module("app").controller("variableDocumentListCtrl", controllers.master.variableDocumentListCtrl);
    }
}